import React, { useEffect, useState } from 'react';
import './App.css'; // Assume styling is managed here
import { FaPhoneAlt, FaCheckCircle, FaBars, FaMapMarkerAlt, FaChevronDown } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import data from './statesAndCities.json'
import { Helmet } from 'react-helmet';

const capitalizeAndFormat = (text: string) => {
    if (!text) return '';
    return text
        .split('-') // Split the string by "-"
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join them back with spaces
};

const sanitizeString = (str: string) => {
    return str.trim().toLowerCase().replace(/\s+/g, "-");
}

interface Props {
    state: string;
    city: string;
    phone: string;
    visualPhone: string;
    showPhone: boolean;
}

const Header: React.FC<Props> = ({ state, city, phone, visualPhone, showPhone }) => {
    return (
        <header className="w-full bg-white sticky top-0 z-20">
            <nav className="w-11/12 max-w-screen-2xl mx-auto flex items-center py-2">

                {/* Left Section */}
                <div className="flex items-center">
                    {/* Logo or Site Name */}
                    <Link
                        className="text-2xl lg:text-3xl font-extrabold text-black uppercase"
                        to={`/nearme-at/${sanitizeString(state)}/${sanitizeString(city)}`}
                    >
                        {city}, {state}
                        <br />
                        Garage Door Repair
                    </Link>
                </div>

                {
                    showPhone
                    && <>

                        {/* Spacer to push content to the right */}
                        <div className="flex-grow"></div>

                        {/* Right Section (Call to Action and Mobile Menu Icon) */}
                        <div className="flex items-center">

                            {/* Call to Action Button */}
                            <span className="hidden lg:block">
                                <a
                                    title="Click to call us"
                                    className="flex items-center gap-3 rounded-full py-3 px-5 lg:px-7 bg-yellow-500 text-white cursor-pointer"
                                    href={`tel:${phone}`}
                                >
                                    <FaPhoneAlt className="h-7 lg:h-9" />
                                    <span>
                                        <p className="font-semibold uppercase">CLICK TO CALL</p>
                                        <p className="-mt-1 text-2xl font-bold lg:text-3xl">
                                            {visualPhone}
                                        </p>
                                    </span>
                                </a>
                            </span>
                            {/* Mobile Menu Icon */}
                            <FaBars className="lg:hidden text-yellow-500 h-8 ml-4 cursor-pointer" />
                        </div>
                    </>
                }

            </nav>
        </header >
    );
};

const HeroSection: React.FC<Props> = ({ state, city, phone, visualPhone, showPhone }) => (
    <section className="w-full flex justify-center flex-col bg-cover bg-center text-black relative pt-4 md:pt-12 pb-24 md:pb-0">
        {/* Background Image */}
        {/* <div className="-z-10 absolute inset-0">
            <img
                src="/garage_door.png"
                alt="Background"
                className="w-full h-full object-cover"
            />
        </div> */}
        <div
            className="-z-10 absolute inset-0 bg-cover bg-center"
            style={{
                backgroundImage: `linear-gradient(to bottom, #000000ba, transparent), url(/garage_door.png)`,
            }}
        ></div>
        {/* Content Container */}
        <div className="pl-0 lg:pl-8 pt-8 pb-16 md:mx-12 md:rounded-tr-[500px] md:rounded-tl-[30px] flex justify-center items-center">
            <div className="z-10 flex w-full items-center justify-between gap-x-4 max-w-7xl">
                {/* Left Side */}
                <div className="w-full">
                    <div className="z-10 flex flex-col justify-center w-auto pl-3 sm:ml-5 md:ml-10 lg:ml-0">

                        {
                            showPhone
                            && <>
                                {/* Call to Action Button */}
                                <a
                                    title="Click to call us"
                                    className="flex w-fit items-center gap-3 rounded-full py-3 px-5 lg:px-7 text-black bg-transparent rounded-xl hover:bg-transparent"
                                    href={`tel:${phone}`}
                                >
                                    <span className="bg-yellow-500 text-white p-2 rounded-full">
                                        <FaPhoneAlt className="h-7 lg:h-9" />
                                    </span>
                                    <span>
                                        <p className="font-semibold uppercase text-yellow-500">
                                            CLICK TO CALL
                                        </p>
                                        <p className="-mt-1 text-3xl font-extrabold">{visualPhone}</p>
                                    </span>
                                </a>
                            </>
                        }

                        {/* Headings */}
                        <div className="mt-2.5 lg:mt-5 text-yellow-500">
                            <h2 className="text-shadow-black text-[25px] md:text-[2.835rem] font-extrabold uppercase">
                                Fix Garage Door Now
                            </h2>
                            <h1 className="text-shadow-black text-[20px] md:text-3xl font-extrabold m-0 text-black">
                                Local Garage Door Repair in {city}, {state}
                            </h1>
                        </div>
                        {/* Service List */}
                        <div className="hidden md:grid w-10/12 max-w-screen-lg text-shadow-black grid-cols-1 xl:grid-cols-2 gap-2 lg:gap-2 mt-5 lg:mt-10">
                            {[
                                "Immediate Garage Door Repairs",
                                "Available 24/7—Call Now",
                                "No Waiting, We Fix It Today",
                                "Rapid Response Time",
                                "Emergency Services Anytime",
                                "Experienced Technicians On-Call",
                                "Your Solution for Broken Doors",
                                "Quick and Reliable Service",
                                "We’re Ready When You Are",
                                "Get Your Garage Door Fixed Now",
                            ].map((service, index) => (
                                <div
                                    key={index}
                                    className="text-black font-semibold text-lg flex items-center space-x-2 w-fit"
                                >
                                    <FaCheckCircle className="h-5 text-yellow-500" />
                                    <div className="truncate">
                                        <p>{service}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                {/* Right Side */}
                <div className="z-10 w-full hidden md:flex flex-col items-center justify-center md:w-2/5">
                    <div className="max-w-[362px] md:w-[362px] bg-[#f6f6f6] rounded-md px-5 py-3 border shadow-md">
                        <h2 className="text-center font-bold text-black mt-0">
                            Request a quote and we'll send you our best price.
                        </h2>
                        <form className="mt-2 flex flex-col gap-3" method="post">
                            {/* Name Input */}
                            <div className="flex items-center gap-2 bg-white rounded p-2 border border-gray-300">
                                <div className="text-gray-500 pr-2">
                                    <FaPhoneAlt className="h-6 w-6" />
                                </div>
                                <input
                                    required
                                    id="desktop-1"
                                    name="contactName"
                                    type="text"
                                    placeholder="Your Name"
                                    className="w-full border-0 p-1 text-black"
                                />
                            </div>
                            {/* Email Input */}
                            <div className="flex items-center gap-2 bg-white rounded p-2 border border-gray-300">
                                <div className="text-gray-500 pr-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M2 3a2 2 0 012-2h12a2 2 0 012 2v1.161l-8 4-8-4V3z" />
                                        <path d="M0 8.839l8 4 8-4V17a2 2 0 01-2 2H2a2 2 0 01-2-2V8.839z" />
                                    </svg>
                                </div>
                                <input
                                    required
                                    id="desktop-2"
                                    name="email"
                                    type="email"
                                    placeholder="E-mail"
                                    className="w-full border-0 p-1 text-black"
                                />
                            </div>
                            {/* Phone Input */}
                            <div className="flex items-center gap-2 bg-white rounded p-2 border border-gray-300">
                                <div className="text-gray-500 pr-2">
                                    <FaPhoneAlt className="h-6 w-6" />
                                </div>
                                <input
                                    required
                                    id="desktop-3"
                                    name="phone"
                                    type="tel"
                                    placeholder="Phone Number"
                                    className="w-full border-0 p-1 text-black"
                                />
                            </div>
                            {/* Message Input */}
                            <div className="flex items-center gap-2 bg-white rounded p-2 border border-gray-300">
                                <textarea
                                    className="w-full border-0 p-1 text-black"
                                    id="desktop-4"
                                    name="message"
                                    placeholder="Message"
                                ></textarea>
                            </div>
                            {/* Submit Button */}
                            <div className="flex items-center gap-2">
                                <button
                                    type="submit"
                                    className="w-full bg-yellow-500 p-2 text-xl text-white rounded"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const InformationSection: React.FC<Props> = ({ showPhone, state, city, phone, visualPhone }) => (

    <section className="w-full flex items-center justify-center flex-col bg-gray-100 py-10 lg:py-12 text-black">
        <div className="w-10/12 lg:w-9/12 max-w-screen-2xl flex flex-col items-center lg:items-start text-center lg:text-left">
            <div className="flex w-full justify-center items-center flex-col">
                <h2 className="text-3xl font-extrabold uppercase text-black">
                    Fix Garage Door Now
                </h2>
                <div className="w-24 h-2 mt-7 mb-3 bg-black"></div>
            </div>
            <div className="my-4">
                <h3 className="text-3xl font-extrabold text-black w-full lg:w-9/12">
                    Immediate Garage Door Repair Services in {city}, {state}
                </h3>
                <div className="mt-3 text-black">
                    <p className="mb-4">
                        Is your garage door broken, noisy, or stuck? Don’t wait! Call us now, and our 24/7 emergency team will be there to fix your garage door immediately. Whether it’s day or night, we’re ready to provide fast, reliable service to get your door back in action.
                    </p>
                    <p className="mb-4">
                        Our expert technicians in <span className='marker'>{city}</span> have the skills and tools to handle any garage door issue. From broken springs to malfunctioning openers, we deliver quick and efficient repairs without delay. Your problem is our priority, and we won’t rest until it’s resolved.
                    </p>
                    <p className="mb-4">
                        Don’t let a broken garage door disrupt your day. With our top-rated local repair services, you’re just a call away from restoring your garage door to perfect working condition. {showPhone && <>Contact us now at <span className='marker'>{visualPhone}</span> for immediate assistance!</>}
                    </p>
                    <p className="mb-4">
                        Searching for "garage door repair near me" in <span className='marker'>{city}, {state}</span>? Look no further! We are your trusted local experts, ready to provide fast and reliable service right in your neighborhood. When you need nearby garage door repair, we’re the top result for a reason—call us now and see why!
                    </p>
                    <p>
                        Whether you need <a href="/garage-door-dents-repair-{city}-{state}"><em><strong>Garage door dents repair</strong></em></a>, <a href="/same-day-garage-door-repair-{city}-{state}"><em><strong>Same day garage door repair</strong></em></a>, or <a href="/garage-door-replacement-{city}-{state}"><em><strong>Garage door replacement</strong></em></a>, we’ve got you covered. Call us now and let us take care of everything!
                    </p>
                </div>
            </div>
        </div>
    </section>
);

const FAQSection: React.FC<Props> = ({ state, city, phone, visualPhone, showPhone }) => {
    const faqs = [
        {
            question: `Can you fix my garage door right now at ${city}, ${state}?`,
            answer: 'Absolutely! Call us anytime, and we will be there 24/7 to provide immediate garage door repair services without any waiting.',
        },
        {
            question: `Are you available near me in ${city}, ${state}?`,
            answer: `Yes! We are your local garage door repair experts in ${city}, ${state}.`,
        },
        {
            question: 'What are your hours of operation?',
            answer: 'We operate 24/7 to offer instant solutions to your garage door problems at any time.',
        },
        {
            question: 'How quickly can you fix my garage door?',
            answer: 'Our technicians are always on standby to fix your garage door now. We prioritize immediate response to get your door working smoothly again.',
        },
        {
            question: 'Do you offer emergency garage door services nearby?',
            answer: `Yes, we provide emergency garage door repair services in Yes! We are your local garage door repair experts in ${city}, ${state}. Just give us a call, and we will be there without delay.`,
        },
    ];


    const [openFAQ, setOpenFAQ] = useState(null);

    const toggleFAQ = (index: any) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    return (
        <section className="w-full flex items-center justify-center flex-col bg-cover bg-center bg-white py-12 lg:py-16">
            <h2 className="text-3xl font-extrabold uppercase text-black text-left">
                FAQs
            </h2>
            <div className="w-24 h-2 mt-7 mb-3 bg-black"></div>
            <div className="w-10/12 mt-5 lg:w-6/12 flex flex-col items-center">
                {faqs.map((faq, index) => (
                    <div key={index} className="w-full mb-2">
                        <button
                            onClick={() => toggleFAQ(index)}
                            className="w-full rounded-lg bg-gray-100 border text-black p-3 px-5 flex items-center justify-between focus:outline-none"
                        >
                            <p className="text-left">{faq.question}</p>
                            <FaChevronDown
                                className={`h-5 transform transition-transform duration-300 ${openFAQ === index ? 'rotate-180' : ''
                                    }`}
                            />
                        </button>
                        {openFAQ === index && (
                            <div className="w-full p-3 px-5 text-left">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}

                {
                    showPhone &&
                    <>
                        <a
                            title="Click to call us"
                            className="btnPrimary bg-yellow-500 flex w-fit items-center gap-3 rounded-full py-3 px-5 text-center lg:px-7 cursor-pointer mt-12"
                            href={`tel:${phone}`}>
                            <span className="text-white">
                                <FaPhoneAlt className="h-7 lg:h-9" />
                            </span>
                            <span>
                                <p className="font-semibold uppercase">CLICK TO CALL</p>
                                <p className="-mt-1 text-3xl font-bold">{visualPhone}</p>
                            </span>
                        </a>
                    </>
                }

            </div>
        </section>
    );
};

interface DirectoryFinderProps {
    states: string[];
    citiesByState: Record<string, string[]>;
    onStateChange: (state: string) => void;
    onCityChange: (city: string) => void;
    selectedState: string;
    selectedCity: string;
}

const DirectoryFinder: React.FC<DirectoryFinderProps> = ({
    states,
    citiesByState,
    onStateChange,
    onCityChange,
    selectedState,
    selectedCity,
}) => {

    const navigate = useNavigate();

    const handleCityClick = (state: string, city: string) => {
        onCityChange(city); // Update selected city
        navigate(`/nearme-at/${sanitizeString(state)}/${sanitizeString(city)}`); // Redirect to the desired URL
    };

    return <>
        <section className="w-full flex flex-col items-center justify-center bg-gray-100 py-10 lg:py-12 text-black">
            <div className="w-10/12 lg:w-9/12 max-w-screen-2xl text-center">
                <h1 className="text-3xl font-extrabold uppercase text-black bg-yellow-500 mb-2">
                    Find Garage Door Professionals Near You
                </h1>
                <h2 className='text-2xl font-extrabold uppercase text-black'>
                    24/7 Garage Door Repair Services
                </h2>
                <div className="w-24 h-2 mt-5 mb-6 bg-black mx-auto"></div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {states.map((state) => (
                        <div key={state} className="flex flex-col items-center">
                            <button
                                onClick={() => onStateChange(state)}
                                className={`w-full py-2 px-4 font-semibold text-black bg-white border-2 border-gray-300 rounded hover:bg-gray-200 focus:outline-none ${selectedState === state ? 'bg-yellow-500' : ''
                                    }`}
                            >
                                {state}
                            </button>
                            {selectedState === state && citiesByState[state] && (
                                <ul className="mt-4 w-full max-h-40 overflow-y-auto bg-white border border-gray-300 rounded">
                                    {citiesByState[state].map((city) => (
                                        <li
                                            key={city}
                                            onClick={() => handleCityClick(state, city)}
                                            className={`py-2 px-4 cursor-pointer hover:bg-gray-200 ${selectedCity === city ? 'bg-gray-300 font-bold' : ''
                                                }`}
                                        >
                                            {city}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </>
};

const GoogleAnalytics = () => {
    useEffect(() => {
        // Load the gtag.js script
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-QP785X48J7';
        document.head.appendChild(script);

        // Initialize gtag
        const inlineScript = document.createElement('script');
        inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-QP785X48J7');`;
        document.head.appendChild(inlineScript);

        console.log('Google Analytics loaded');

        // Cleanup scripts on component unmount
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(inlineScript);
        };
    }, []);

    return null;
};

const App = () => {

    const { prekey, state, city } = useParams();

    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedCity, setSelectedCity] = useState<string>('');

    // const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas']; // Example states
    // const citiesByState = {
    //     Alabama: ['Birmingham', 'Montgomery', 'Mobile'],
    //     Alaska: ['Anchorage', 'Juneau', 'Fairbanks'],
    //     Arizona: ['Phoenix', 'Tucson', 'Mesa'],
    //     Arkansas: ['Little Rock', 'Fayetteville', 'Fort Smith'],
    // };

    const handleStateChange = (state: string) => {
        setSelectedState(state);
        setSelectedCity(''); // Reset city selection when state changes
    };

    const handleCityChange = (city: string) => {
        setSelectedCity(city);
    };

    if (!state || !city) {

        const { states, citiesByState } = data;

        return <>
            <Helmet>
                <title>{`Find Garage Door Repair Near Me`}</title>
                <meta name="description" content={`Find garage door rapair near me`} />
            </Helmet>
            <div className=''>
                <GoogleAnalytics />
                <DirectoryFinder states={states}
                    citiesByState={citiesByState}
                    onStateChange={handleStateChange}
                    onCityChange={handleCityChange}
                    selectedState={selectedState}
                    selectedCity={selectedCity}
                />
            </div>
        </>
    }

    const formattedState = capitalizeAndFormat(state);
    const formattedCity = capitalizeAndFormat(city);

    const phone = '8632523016';
    const visualPhone = '(863) 252-3016';
    const showPhone = false;

    return (
        <div className="w-full">
            <Helmet>
                <title>{`Garage Door Repair Near Me in ${formattedCity}, ${formattedState}`}</title>
                <meta name="description" content={`Garage door rapair near me at ${formattedCity}, ${formattedState}`} />
            </Helmet>
            <GoogleAnalytics />
            <Header phone={phone} visualPhone={visualPhone} state={formattedState} city={formattedCity} showPhone={showPhone} />
            <HeroSection phone={phone} visualPhone={visualPhone} state={formattedState} city={formattedCity} showPhone={showPhone} />
            <InformationSection phone={phone} visualPhone={visualPhone} state={formattedState} city={formattedCity} showPhone={showPhone} />
            <FAQSection phone={phone} visualPhone={visualPhone} state={formattedState} city={formattedCity} showPhone={showPhone} />
        </div>
    );
};

export default App;
